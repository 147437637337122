<script>
import PictureLazy from '@maison/components/common/PictureLazy';

export default {
  components: {
    PictureLazy,
  },

  props: {
    image: {
      type: Object,
      required: true,
    },
    preTitle: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    url: {
      type: String,
      required: true,
    },
    isDiscontinuedReference: {
      type: Boolean,
      default: false,
    },
    dataTracking: {
      type: Object,
      default: null,
    },
  },

  methods: {
    pushTracking() {
      window.tracking.pushEvent(this.dataTracking);
    },
  },
};
</script>

<template>
  <div class="product-card--search product-card--horizontal" :class="{ 'text-muted': isDiscontinuedReference }">
    <div class="picture picture--contain product-card__picture">
      <picture-lazy v-bind="image" />
    </div>

    <div class="product-card-card__description">
      <a :href="url" class="product-card__link expand-target" @click="pushTracking">
        <div class="product-card-card__pretitle">{{ preTitle }}</div>
        <div class="product-card-card__title" v-html="title"></div>
      </a>
    </div>
  </div>
</template>
