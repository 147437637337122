<script>
import { mapState } from 'vuex';
import SearchSuggestionCard from '@maison/components/search/SearchSuggestionCard';
import SearchPanel from '@phoenix/mixins/components/search/SearchPanel';

export default {
  components: {
    SearchSuggestionCard,
  },

  mixins: [SearchPanel],

  props: {
    isMobile: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    ...mapState('search', ['stagedQuery']),

    hasStagedQuery() {
      return this.stagedQuery !== '';
    },

    results() {
      return this.hasStagedQuery ? this.suggestions : this.suggestedPages;
    },

    viewAllText() {
      return this.$t('Search.viewAll', { query: this.stagedQuery });
    },

    viewAllLink() {
      return `${this.resultsUrl}?keyword=${this.stagedQuery}`;
    },

    wrapperClass() {
      return this.isMobile ? 'mega-menu-mobile__search-results' : 'mega-menu-search__suggestions';
    },

    wrapperId() {
      return this.isMobile ? 'mega-menu-mobile-search-results' : '';
    },

    shouldDisplayTitle() {
      return !this.isMobile && !this.hasStagedQuery;
    },

    dataTracking() {
      return window.tracking.getSearchResultsTracking(this.stagedQuery, this.suggestions.length);
    },
  },

  methods: {
    formatResultName(name) {
      return name.replace(new RegExp(this.query, 'gi'), '<strong>$&</strong>').toUpperCase();
    },
  },
};
</script>

<template>
  <div :id="wrapperId" :class="wrapperClass">
    <div v-if="shouldDisplayTitle" class="text-muted">{{ $t('Search.suggestions.title') }}</div>

    <ul class="list list--stacked mt-base">
      <li v-for="result in results" :key="result.id">
        <search-suggestion-card
          :image="result.search_suggestion_img"
          :pre-title="result.search_suggestion_full_pretitle ?? result.search_suggestion_pretitle"
          :title="!hasStagedQuery ? result.navigation_title : formatResultName(result.navigation_title)"
          :url="result.link ?? result.url"
          :is-discontinued-reference="result.is_discontinued_reference"
          :data-tracking="dataTracking"
        ></search-suggestion-card>
      </li>
    </ul>

    <a v-if="hasStagedQuery" :href="viewAllLink" class="btn btn--link mega-menu-search-more">
      <span class="btn__text">{{ viewAllText }}</span>
    </a>
  </div>
</template>
